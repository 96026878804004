<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5">
        <v-card class="elevation-12">
          <v-toolbar dark flat>
            <v-toolbar-title>{{ $t("forms.login.title") }}</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="submitForm">
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('forms.general.email.label')"
                      name="email"
                      :prepend-icon="
                        $vuetify.breakpoint.smAndUp ? 'mdi-at' : ''
                      "
                      type="email"
                      v-model="form.email"
                      :error-messages="emailErrors"
                      :success="shouldAppendValidClass($v.form.email)"
                      autocomplete="email"
                      @blur="$v.form.email.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      :label="$t('forms.general.password.label')"
                      name="password"
                      :prepend-icon="
                        $vuetify.breakpoint.smAndUp ? 'mdi-lock' : ''
                      "
                      type="password"
                      v-model="form.password"
                      :error-messages="passwordErrors"
                      :success="shouldAppendValidClass($v.form.password)"
                      autocomplete="password"
                      @blur="$v.form.password.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-dialog
                      v-model="showDialog"
                      width="500"
                      @input="prefillPasswordForgottenForm"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" x-small text>
                          {{ $t("forms.passwordForgotten.action") }}
                        </v-btn>
                      </template>

                      <v-card>
                        <v-toolbar flat>
                          <v-toolbar-title>
                            {{ $t("forms.passwordForgotten.title") }}
                          </v-toolbar-title>

                          <v-spacer></v-spacer>

                          <v-btn icon @click="showDialog = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar>

                        <v-divider></v-divider>

                        <v-form @submit.prevent="submitPasswordForgotten">
                          <v-container>
                            <v-row dense>
                              <v-col cols="12">
                                <v-text-field
                                  :label="$t('forms.general.email.label')"
                                  type="email"
                                  v-model="passwordForgotten.email"
                                  :error-messages="passwordForgottenEmailErrors"
                                  :success="
                                    shouldAppendValidClass(
                                      $v.passwordForgotten.email
                                    )
                                  "
                                  autocomplete="email"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-alert
                                  v-if="passwordForgotten.success"
                                  class="mt-6"
                                  dense
                                  outlined
                                  type="success"
                                >
                                  {{ $t(passwordForgotten.success) }}
                                </v-alert>
                                <v-alert
                                  v-if="passwordForgotten.error"
                                  class="mt-6"
                                  dense
                                  outlined
                                  type="error"
                                >
                                  {{ $t(passwordForgotten.error) }}
                                </v-alert>
                              </v-col>
                            </v-row>
                          </v-container>

                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                              type="submit"
                              color="success"
                              :loading="passwordForgotten.loading"
                            >
                              {{ $t("forms.passwordForgotten.submit") }}
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-alert v-if="error" class="mt-6" dense outlined type="error">
                  {{ errorMessage }}
                </v-alert>
              </v-container>

              <v-card-actions>
                <v-btn
                  color="primary"
                  :to="{ name: 'SignUp' }"
                  class="flex-grow-1"
                >
                  {{ $t("forms.signUp.createAccount") }}
                </v-btn>

                <v-btn type="submit" color="success" class="flex-grow-1">
                  {{ $t("forms.login.submit") }}
                </v-btn>
              </v-card-actions>

              <v-card-actions class="flex justify-center text-button">
                {{ $t("forms.login.or") }}
              </v-card-actions>

              <v-card-actions id="google" class="flex justify-center">
              </v-card-actions>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */

import Repository from "../store/api/repository";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: null,
        password: null
      },
      passwordForgotten: {
        email: null,
        loading: false,
        success: null,
        error: null
      },
      error: null,
      showDialog: false
    };
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://alge-results.com/login`
        }
      ]
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required }
    },
    passwordForgotten: {
      email: { required, email }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      isDevSystem: "settings/isDevSystem"
    }),
    errorMessage() {
      return this.$i18n.t(this.error);
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t("forms.general.email.errors.required"));
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t("forms.general.email.errors.format"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
        errors.push(this.$i18n.t("forms.general.password.errors.required"));
      return errors;
    },
    passwordForgottenEmailErrors() {
      const errors = [];
      if (!this.$v.passwordForgotten.email.$dirty) return errors;
      !this.$v.passwordForgotten.email.required &&
        errors.push(this.$i18n.t("forms.general.email.errors.required"));
      !this.$v.passwordForgotten.email.email &&
        errors.push(this.$i18n.t("forms.general.email.errors.format"));
      return errors;
    }
  },
  methods: {
    ...mapMutations({
      setToken: "user/setToken",
      setUser: "user/setUser"
    }),
    ...mapActions({
      authenticateUser: "user/authenticateUser",
      fetchDevices: "devices/fetchItems"
    }),
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    submitForm() {
      this.error = null;

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.authenticateUser({
          username: this.form.email,
          password: this.form.password
        })
          .then(response => {
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push({ name: "Dashboard" });
            }
          })
          .catch(response => {
            switch (response.data.status) {
              case -1000:
                this.error = "forms.login.errors.wrongCredentials";
                break;
              case -1015:
                this.error = "forms.login.errors.noPasswordAssociated";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
          });
      }
    },
    prefillPasswordForgottenForm() {
      this.$v.passwordForgotten.$reset();
      this.passwordForgotten.email = this.form.email;
      this.passwordForgotten.success = null;
      this.passwordForgotten.error = null;
    },
    submitPasswordForgotten() {
      this.passwordForgotten.success = null;
      this.passwordForgotten.error = null;

      this.$v.passwordForgotten.$touch();

      if (
        !this.$v.passwordForgotten.$invalid &&
        !this.passwordForgotten.loading
      ) {
        this.passwordForgotten.loading = true;

        const payload = {
          value: this.passwordForgotten.email
        };

        Repository.post("password/reset", payload)
          .then(response => {
            this.passwordForgotten.loading = false;

            if (response.data.status !== 0) {
              return Promise.reject(response);
            }

            this.passwordForgotten.success = "forms.passwordForgotten.success";
          })
          .catch(response => {
            this.passwordForgotten.loading = false;

            switch (response.data.status) {
              case -1000:
                this.passwordForgotten.error =
                  "forms.passwordForgotten.errors.invalidCredentials";
                break;
              default:
                this.passwordForgotten.error = "errors.unexpectedError";
            }
          });
      }
    },
    initSignInWithGoogleClient() {
      const options = {
        client_id: this.isDevSystem
          ? process.env.VUE_APP_GOOGLE_CLIENT_DEV_ID
          : process.env.VUE_APP_GOOGLE_CLIENT_PROD_ID,
        callback: this.handleCredentialResponse
      };

      google.accounts.id.initialize(options);
    },
    renderSignInWithGoogleButton() {
      const parent = document.querySelector("#google");
      const options = {
        theme: "filled_blue"
      };

      google.accounts.id.renderButton(parent, options);
    },
    handleCredentialResponse(response) {
      const payload = {
        token: response.credential
      };

      Repository.post("user/oauth/login", payload)
        .then(response => {
          const token = response.headers.authorization;
          const user = response.data.data[0];
          this.setToken(token);
          this.setUser(user);
          this.fetchDevices(user.id).catch(() => {});

          if (response.data.status === 0) {
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push({ name: "Dashboard" });
            }
          } else if (response.data.status === -1013) {
            this.$router.push({ name: "Account" });
          } else {
            return Promise.reject(response);
          }
        })
        .catch(() => {
          this.error = "forms.login.errors.googleSignInFailure";
        });
    }
  },
  mounted() {
    this.initSignInWithGoogleClient();
    this.renderSignInWithGoogleButton();

    if (this.user) {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
